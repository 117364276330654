import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import {
  EntityGroupProfileWithManager,
  EntityMembersListWithManager,
} from '@efg/backstage-plugin-org-with-manager';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  DoraMetricsGroupCard,
  isDoraMetricsAvailable,
} from '@efg/backstage-plugin-dora-metrics';
import {
  CostMetricsGroupCard,
  isCostMetricsAvailable,
} from '@efg/backstage-plugin-cost-metrics';
import { LeaderboardTeamScoreCard } from '@efg/backstage-plugin-leaderboard';
import { FollowUpCard } from '@efg/backstage-plugin-incident-react';
import { SLOTable } from '@efg/backstage-plugin-slo';
import { AlertsTableWrapper } from '@efg/backstage-plugin-prometheus';
import { entityWarningContent } from '../EntityContent/EntityWarningContent';
import { techdocsRoutableContent } from '../EntityContent/TechdocsContent';
import { ReliabilityCard } from './GroupPage/ReliabilityCard';
import { ReliabilityInfoDialog } from './GroupPage/ReliabilityInfoDialog';
import { DowntimeCard } from './GroupPage/DowntimeCard';
import { UptimeCard } from './GroupPage/UptimeCard';

export const GroupPage = () => {
  const { entity: groupEntity } = useAsyncEntity();
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={1}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <EntityGroupProfileWithManager variant="fullHeight" />
          </Grid>
          <EntitySwitch>
            <EntitySwitch.Case if={isDoraMetricsAvailable}>
              <Grid item xs={12} md={6} style={{ display: 'flex' }}>
                <DoraMetricsGroupCard />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
          <Grid item xs={12} md={4}>
            <LeaderboardTeamScoreCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReliabilityCard />
          </Grid>
          <EntitySwitch>
            <EntitySwitch.Case if={isCostMetricsAvailable}>
              <Grid item xs={12} md={4}>
                <CostMetricsGroupCard />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
          <Grid item xs={12} md={12}>
            <EntityMembersListWithManager />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {techdocsRoutableContent({ if: isTechDocsAvailable })}
      <EntityLayout.Route path="/ownership" title="Ownership">
        <EntityOwnershipCard variant="fullHeight" />
      </EntityLayout.Route>
      <EntityLayout.Route path="/reliability" title="Reliability">
        <Grid container spacing={1}>
          <Grid item xs={12} md={10}>
            <FollowUpCard />
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <ReliabilityInfoDialog />
              </Grid>
              <Grid item xs={12} md={12}>
                <DowntimeCard />
              </Grid>
              <Grid item xs={12} md={12}>
                <UptimeCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <SLOTable
              filter={{ team: groupEntity?.metadata.name }}
              pageSize={10}
              nowrapSLOName
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AlertsTableWrapper />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
