import { InfoCard, Progress } from '@backstage/core-components';
import Alert from '@mui/material/Alert';
import React from 'react';
import { GroupEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SLOTable } from '@efg/backstage-plugin-slo';
import { useGroupFollowups } from '@efg/backstage-plugin-incident-react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Box, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Heading } from './ReliabilityInfoDialog';

export const ReliabilityCard = () => {
  const { entity: group } = useEntity<GroupEntity>();

  const { loading, error, value: followups } = useGroupFollowups(group);

  if (loading) return <Progress />;
  if (error) return <Alert severity="error">{error.message}</Alert>;
  if (!followups) {
    return <Alert severity="error">Followups not found</Alert>;
  }

  const subheader =
    followups.length > 0
      ? `Team has ${followups.length} outstanding incident follow ups.`
      : `No outstanding follow-ups 👍`;

  return (
    <InfoCard
      variant="fullHeight"
      title={
        <Typography variant="inherit" component="div">
          Service Reliability{' '}
          <Tooltip
            title={
              <>
                <Heading>Incident follow-ups</Heading>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Incident follow-ups should be resolved in a reasonable amount
                  of time.
                </Typography>
                <Box sx={{ mt: 2 }} />
                <Heading>SLOs at risk</Heading>
                <Typography variant="body2">
                  SLOs with less than 10% of error budget left.
                </Typography>
              </>
            }
          >
            <InfoIcon
              fontSize="small"
              style={{ marginLeft: 8, cursor: 'pointer' }}
            />
          </Tooltip>
        </Typography>
      }
      subheader={subheader}
      deepLink={{
        link: `reliability`,
        title: `Reliability`,
      }}
      noPadding
    >
      <CardContent sx={{ pt: 1 }}>
        <Typography variant="subtitle1" sx={{ pb: 1 }}>
          At Risk SLOs
        </Typography>
        <SLOTable
          title=""
          showColumns={{
            'metadata.service': false,
            'metadata.team': false,
            'spec.kind': false,
            'spec.window': false,
            'spec.objective': false,
            'status.errors': false,
            'status.total': false,
            'status.availability': false,
          }}
          filter={{ team: group.metadata.name, onlyAtRisk: true }}
          pageSize={10}
        />
      </CardContent>
    </InfoCard>
  );
};
